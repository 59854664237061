import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CFixedImg,
  CDefinition,
  ConceptMedia,
  LStayContact,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: (
              <>
                IN-ROOM <br />
                RELAXATION
              </>
            ),
            sub: 'インルームリラクゼーション',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/relaxation/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/relaxation/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'ご宿泊',
                path: '/stay/',
              },
            ],
            current: {
              label: 'インルームリラクゼーション',
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect02 u_mb90_pc">
        <LWrap>
          <p className="c_sectLead">
            ご宿泊のお部屋でお受けいただくトリートメントメニューです。
          </p>
          <CDefinition
            exClass="u_mb20"
            data={[
              {
                title: '最終営業日',
                text: (
                  <>
                   2025年3月31日（月）午前4:00まで
                  </>
                ),
              },
              {
                title: '料金',
                text: (
                  <>
                    アロマエステ（フルボディ）
                    <ul className="c_circleList u_mb25">
                      <li>60分　13,500円</li>
                      <li>90分　18,500円</li>
                      <li>120分　23,500円</li>
                      <li>延長（30分）　6,000円</li>
                      <li>指名料　2,000円</li>
                    </ul>
                    もみほぐし
                    <ul className="c_circleList u_mb90">
                      <li>45分　9,000円</li>
                      <li>60分　11,000円</li>
                    </ul>
                  </>
                ),
              },
              {
                title: '時間',
                text: (
                  <>
                    アロマエステ　19:00～28:00
                    <br />
                    もみほぐし　19:00～24:00
                  </>
                ),
              },
              {
                title: '予約（要予約）',
                text: (
                  <>
                    インルームリラクゼーション
                    <br />
                    TEL <a href="tel:0120350799">0120-350-799</a>
                    （19:00～28:00）
                  </>
                ),
              },
            ]}
          />
          <ul className="c_noteList u_mb90">
            <li>
              部屋タイプやご利用人数等によりご希望に添えない場合もございます。詳しくはお問合せください。
            </li>
            <li>和室スイートでは承っておりません。</li>
            <li>妊娠中の方の施術はご遠慮いただいております。</li>
            <li>料金には消費税が含まれます。</li>
          </ul>
        </LWrap>
      </section>
      <LStayContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
